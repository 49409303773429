import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Button } from "@atoms";
import { AppLink } from "@base";

const HeaderNav = ({ links }) => {
  return (
    <ul className="flex items-center font-semibold tracking-0.5 -mx-3 lg:-mx-4">
      {links?.map(link => {
        switch (link.style) {
          case "button":
            return (
              <li className="font-serif italic text-white px-3 lg:px-4">
                <Button color="transparent" size="xs" to={link.link.url}>
                  {link.link.text}
                </Button>
              </li>
            );
          case "":
            return <li />;
          default:
            return (
              <li className="px-3 lg:px-4">
                <AppLink
                  activeClassName="underline-1-yellow"
                  className="font-serif italic text-white underline-1--hover-header transition duration-500"
                  to={link.link.url}
                >
                  {link.link.text}
                </AppLink>
              </li>
            );
        }
      })}
    </ul>
  );
};

HeaderNav.defaultProps = {};

HeaderNav.propTypes = {};

export default HeaderNav;
