import React from "react";
import propTypes from "prop-types";

const StickyElement = ({ children, className: _className }) => {
  return (
    <div>
      <div className={`relative md:sticky ${_className}`}>{children}</div>
    </div>
  );
};

StickyElement.defaultProps = {};

StickyElement.propTypes = {};

export default StickyElement;
