import resolveImage from "./resolveImage";

const resolveCB = contentBuilder =>
  contentBuilder.map(block => {
    const { typeHandle: type } = block;
    switch (type) {
      case "heading":
        return {
          type,
          heading: block.heading,
        };
      case "subheading":
        return {
          type,
          subheading: block.subheading,
        };
      case "leadText":
        return {
          type,
          copy: block.leadText,
        };
      case "copy":
        return {
          type,
          copy: block.copy,
          indent: block.indent,
        };
      case "list":
        return {
          type,
          copy: block.listItem,
          restartCount: block.restartCount,
        };
      case "image":
        return {
          type,
          image: resolveImage(block.images),
          indent: block.indent,
        };
      case "video":
        return {
          type,
          indent: block.indent,
          videoUrl: block.videoUrl,
          caption: block.caption,
        };
      case "link":
        return {
          type,
          link: { url: block.linkLink.url, text: block.linkLink.text },
          indent: block.indent,
          margin: block.removeTopMargin ? "removeTop" : "default",
        };
      case "button":
        return {
          type,
          link: { url: block.linkLink.url, text: block.linkLink.text },
          indent: block.indent,
          margin: block.removeTopMargin ? "removeTop" : "default",
        };
      case "blockQuote":
        return {
          type,
          quote: block.quote,
          citation: block.citation,
        };
      default:
        return null;
    }
  });

export default resolveCB;
