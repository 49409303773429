import { createAppState } from "@designbycosmic/cosmic-react-state-management";
import layout from "./reducers/layout";

const reducer = {
  layout: layout.reducer,
};

const initialState = {
  layout: layout.initialState,
};

export const {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
} = createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
