import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import propTypes from "prop-types";
import { Header, Announcement } from "@organisms";
// import { useAppState } from "@state/state";
// import BrowserWarning from "../atoms/BrowserWarning";

const DefaultLayout = ({ children }) => {
  return (
    <div id="app_layout" className="bg-blue">
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&family=Josefin+Sans:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap"
          rel="preload"
          as="style"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&family=Josefin+Sans:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Announcement />
      <Header />
      {children}
      {/* <BrowserWarning /> */}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default DefaultLayout;
