import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import { Container, Text, Icon, Button, ButtonAlt } from "@atoms";
import MiniSearch from "minisearch";
import { AppLink } from "@base";
import gsap from "gsap";
import classNames from "classnames";

const SearchModal = ({
  placeholder,
  content,
  resultCard: ResultCard,
  openState,
  param,
  heading,
  backCopy,
}) => {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [modalOpen, setModalOpen] = openState || useState(false);
  const [visibleResults, setVisibleResults] = useState(12);
  const searchInput = useRef();
  const tl = useRef();
  const modal = useRef();

  const miniSearch = new MiniSearch({
    fields: Object.keys(content[0]), // fields to index for full-text search
    // storeFields: ["title", "category"], // fields to return with search results
    searchOptions: {
      fuzzy: 0.1,
      prefix: true,
    },
  });

  miniSearch.addAll(content);

  useEffect(() => {
    if (query && !!query.length) {
      const _results = miniSearch.search(query);
      setResults(_results.map(r => content.find(c => c.id === r.id)));
    } else {
      setResults(content);
    }
  }, [query]);

  useEffect(() => {
    tl.current = gsap
      .timeline()
      .pause()
      .set(modal.current, { display: "none" })
      .set(modal.current, { display: "flex" })
      .fromTo(modal.current, { opacity: 0 }, { opacity: 1, duration: 0.2 })
      .fromTo(
        modal.current.children,
        { opacity: 0, y: 40 },
        {
          opacity: 1,
          duration: 0.2,
          stagger: 0.1,
          y: 0,
          onComplete: () => {
            searchInput.current.focus();
          },
        }
      );
    if (window) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const q = urlParams.get(param);
      if (q) {
        setModalOpen(true);
        setQuery(q);
        searchInput.current.value = q;
      }
    }
    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (modalOpen) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [modalOpen]);

  return (
    <div
      ref={modal}
      className="fixed hidden opacity-0 items-start justify-center inset-0 z-60 p-4 sm:p-6 md:p-10 lg:p-12 xl:p-24"
    >
      <button
        type="button"
        className="z-0 block absolute w-full h-full inset-0 text-transparent"
        onClick={() => setModalOpen(false)}
      >
        <span className="absolute inset-0 opacity-90 bg-black" />
        close
      </button>
      <div className="absolute sm:hidden top-0 right-0 p-3">
        <button
          type="button"
          className="text-rose"
          onClick={() => setModalOpen(false)}
        >
          <Icon name="close" className="h-4 w-4 text-rose" />
        </button>
      </div>
      <div className="mx-auto overflow-y-scroll h-full w-full relative z-10 opacity-0">
        <Container variant="xs">
          <div className="bg-white w-full pt-0 p-4 sm:pt-0 sm:p-6 md:pt-0 md:p-10 lg:pt-0 lg:p-12 relative">
            <div className="sticky z-20 pb-6 bg-white top-0 pt-4 sm:pt-6 md:pt-10 lg:pt-12 px-4 sm:px-6 md:px-10 lg:px-12 -mx-4 sm:-mx-6 md:-mx-10 lg:-mx-12">
              <div className="absolute hidden sm:block top-0 right-0 p-3">
                <button
                  type="button"
                  className="text-rose"
                  onClick={() => setModalOpen(false)}
                >
                  <Icon name="close" className="h-4 w-4" />
                </button>
              </div>
              <div className="relative">
                <input
                  ref={searchInput}
                  type="text"
                  className="w-full p-2 bg-white border border-yellow font-serif font-bold text-blue placeholder-gray-dark"
                  placeholder={placeholder}
                  onChange={e => {
                    setQuery(e.target.value);
                  }}
                />
                <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center">
                  <Icon
                    name="search"
                    className="h-8 text-yellow w-8 p-2 pr-3"
                  />
                </div>
              </div>
            </div>
            <ul className="w-full relative z-0">
              {(!results || results.length === 0) && !!query.length && (
                <Text variant="body--tight">
                  Your search return no results. Please try refining your query
                  and try again.
                </Text>
              )}
              {!query.length && (
                <Text variant="body--tight" className="mb-4">
                  Enter a search query above to filter results.
                </Text>
              )}
              {!!query.length && !!results.length && (
                <Text
                  variant="h3"
                  className="mb-4 text-teal font-bold text-center"
                >
                  {heading}
                </Text>
              )}
              {results &&
                // results.length &&
                results.slice(0, visibleResults).map(r => (
                  <li
                    key={`search-result-${r.id}`}
                    className="w-full text-left"
                  >
                    <ResultCard {...r} />
                  </li>
                ))}
              <div
                className={`flex items-center justify-center mt-12 ${
                  visibleResults >= results.length ? "hidden" : ""
                }`}
              >
                <Button
                  onClick={() => {
                    setVisibleResults(visibleResults + 6);
                  }}
                >
                  Load More
                </Button>
              </div>
              {backCopy && (
                <div className="mt-6 flex items-center justify-center">
                  <button
                    type="button"
                    className="underline-1 font-serif italic leading-tight font-semibold"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                    reverse
                  >
                    {backCopy}
                  </button>
                </div>
              )}
            </ul>
          </div>
        </Container>
      </div>
    </div>
  );
};

SearchModal.defaultProps = {
  placeholder: "Search",
  param: "q",
};

SearchModal.propTypes = {
  resultCard: propTypes.func.isRequired,
  placeholder: propTypes.string,
  param: propTypes.string,
};

export default SearchModal;
