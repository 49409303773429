import React from "react";
import propTypes from "prop-types";
import { AppLink } from "@base";
import { useAppState } from "@state/state";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
}) => {
  const [{ ec }, dispatch] = useAppState();
  const colors = {
    orange: "bg-orange",
    yellow: "bg-yellow text-rose hover:text-blue italic",
    rose: "bg-rose",
    blue: "bg-teal",
    brown: "bg-brown",
    teal: "bg-teal",
    white: "bg-white",
    black: "bg-black",
    offwhite: "bg-offwhite",
    gray: "bg-gray",
    bluegray: "bg-tealgray",
    transparent:
      "bg-transparent hover:bg-offwhite hover:bg-opacity-12 border border-offwhite text-offwhite",
  };
  let classes = `${colors[color]} inline-flex justify-center items-center cursor-pointer items-center relative z-0 font-bold group leading-tighter transition duration-500`;

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 text-xs";
      break;
    case "xxs":
      classes += " text-xs";
      break;
    case "xs":
      classes += " px-5 py-2 text-xxs";
      break;
    case "md":
      classes += " px-4 py-2 text-xs";
      break;
    case "lg":
      classes += " px-10 py-5 text-base";
      break;
    case "sm":
      classes += " py-3 px-6 text-xs";
      break;
    default:
      classes += " py-4 px-10 text-sm";
      break;
  }

  if (rounded) {
    classes += " rounded-full";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={`${classes}`} onClick={onClick}>
        <span className="flex relative z-10 text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${classes}`}
      onClick={onClick}
    >
      <span className="flex relative z-10 text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: null,
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

Button.propTypes = {
  linkObj: propTypes.func,
  color: propTypes.string,
  to: propTypes.string,
  title: propTypes.string,
  type: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Button;
