import moment from "moment-timezone";
import resolveImage from "./resolveImage";

const resolveEvent = event => {
  const {
    title,
    slug,
    url,
    eventType,
    eventStartTime,
    showDate,
    eventEndTime,
    metaDescription,
    eventImage,
    repeats: _repeats,
  } = {
    ...event,
  };

  const repeats = !!_repeats?.length;

  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getTime = (r, t) => {
    const etime = `${moment(t).format("h:mm a")}`;
    if (r.length < 7) {
      return `Every ${r.map(rr => capitalize(rr)).join(", ")} at ${etime}`;
    }
    return `Daily at ${etime}`;
  };

  const getStartTime = (r, t) => {
    const etime = `${moment(t).format("h:mm a")}`;
    const today = moment().utc().valueOf();
    const eDays = r.map(rr => moment(etime).day(rr).utc().valueOf());
    const eDay = eDays.find(rr => rr >= today);
    return eDay;
  };

  const times =
    moment(eventStartTime).tz("America/Los_Angeles").format("a") ===
    moment(eventEndTime).tz("America/Los_Angeles").format("a")
      ? `${moment(eventStartTime)
          .tz("America/Los_Angeles")
          .format("h:mm")} - ${moment(eventEndTime)
          .tz("America/Los_Angeles")
          .format("h:mm a")}`
      : `${moment(eventStartTime)
          .tz("America/Los_Angeles")
          .format("h:mm a")} - ${moment(eventEndTime)
          .tz("America/Los_Angeles")
          .format("h:mm a")}`;

  const time = repeats
    ? getTime(_repeats, eventStartTime)
    : moment(eventStartTime)
        .tz("America/Los_Angeles")
        .format("MM/DD/YY h:mm a");

  const startTime = repeats
    ? getStartTime(_repeats, eventStartTime)
    : eventStartTime;

  return {
    title,
    slug,
    url,
    showDate,
    description: metaDescription,
    excerpt:
      metaDescription?.length > 83
        ? `${metaDescription?.slice(0, 80)}...`
        : metaDescription,
    image: resolveImage(eventImage),
    type: eventType,
    time,
    orderTime: moment(startTime).utc().valueOf(),
    date: moment(eventStartTime).tz("America/Los_Angeles").format("MM/DD/YY"),
    times,
    repeats,
  };
};

export default resolveEvent;
