import React from "react";
import propTypes from "prop-types";
import { Image } from "@atoms";

const ImageRight = ({ children, image, className: _className }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between max-w-5xl mx-auto px-4 sm:px-8 md:px-10 lg:px-12 xl:px-20 lg:space-x-8">
      <div className="mb-4">{children}</div>

      <div
        className={`self-start max-w-md md:w-1/2 w-full shadow-lg shrink ${_className}`}
      >
        <Image {...image} />
      </div>
    </div>
  );
};

ImageRight.defaultProps = {};

ImageRight.propTypes = {};

export default ImageRight;
