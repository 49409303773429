import React from "react";
import propTypes from "prop-types";
import { Text, Icon } from "@atoms";

const HeadingWithCopy = ({
  children,
  heading,
  headingColor,
  iconColor,
  className: _className,
}) => {
  const colors = {
    orange: "text-orange",
    yellow: "text-yellow",
    rose: "text-rose",
    blue: "text-blue",
    brown: "text-brown",
    teal: "text-teal",
    white: "text-white",
    black: "text-blue",
    offwhite: "text-offwhite",
    gray: "text-gray",
    bluegray: "text-bluegray",
  };
  return (
    <div className="text-center flex flex-col py-8 px-4 mx-auto w-full">
      <Text
        variant="h3"
        className={`font-serif font-bold ${colors[headingColor]} mb-4 ${_className}`}
      >
        {heading}
      </Text>

      <div className="flex justify-center mb-4">
        <Icon name="ramFlowers" className={`h-7 w-32 ${colors[iconColor]}`} />
      </div>

      {children}
    </div>
  );
};

HeadingWithCopy.defaultProps = {};

HeadingWithCopy.propTypes = {};

export default HeadingWithCopy;
