import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { TemplePattern } from "@molecules";
import { Image, Text, Button, Container, BackgroundVideo } from "@atoms";

const HeroHome = ({ heading, button, image, mediaUrl, scrimOpacity }) => {
  return (
    <>
      <section className="w-full bg-black overflow-hidden flex justify-center items-center relative pt-28 pb-16 md:py-60">
        <Container>
          <TemplePattern />
          <div
            className="w-full absolute top-0 left-0 right-0 h-40 bg-gradient-to-t from-transparent to-black opacity-50 z-10"
            style={{ opacity: scrimOpacity }}
          />
          <div
            className="w-full absolute top-0 left-0 right-0 h-20 bg-gradient-to-t from-transparent to-black-alpha opacity-50 z-10"
            style={{ opacity: scrimOpacity }}
          />
          <div
            className="w-full h-full absolute top-0 left-0 right-0 bg-gradient-to-t from-black to-black-alpha opacity-50 z-10"
            style={{ opacity: scrimOpacity }}
          />
          <div className="absolute inset-0 z-0">
            <Image {...image} fill />
          </div>
          <div className="absolute inset-0 z-0">
            <div
              className="absolute"
              style={{
                top: "-50%",
                right: "-50%",
                left: "-50%",
                bottom: "-50%",
              }}
            >
              <BackgroundVideo url={mediaUrl} />
            </div>
          </div>
          <div className="relative z-20">
            <div style={{ textShadow: "0 0 40px black" }}>
              <Text
                variant="h1"
                className="text-offwhite text-center mb-8 md:mb-10 font-serif text-3xl md:text-5xl lg:text-7xl"
              >
                {heading}
              </Text>
            </div>

            <div className="flex flex-nowrap justify-center">
              <Button to={button?.url} color="yellow" size="md">
                <Text variant="body" className="font-serif italic">
                  {button?.text}
                </Text>
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

HeroHome.defaultProps = {};

HeroHome.propTypes = {};

export default HeroHome;
