import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Button } from "@atoms";
import { MediaCard, MediaPreview } from "@molecules";

const TeachingsHome = ({
  heading,
  content,
  mediaLabel,
  media,
  button,
  preview,
}) => {
  return (
    <div className=" mb-14 p-4">
      <div className="flex flex-col lg:flex-row space-y-8 lg:space-x-8 lg:space-y-0 justify-center mb-10 items-start">
        <div className="w-full max-w-sm">
          <Text
            variant="h3"
            className="text-yellow font-serif font-bold w-full max-w-xs leading-loose md:leading-tight"
          >
            {heading}
          </Text>

          <Text
            variant="body"
            className="text-offwhite font-serif text-lg leading-snug md:leading-snug my-4 pr-4"
          >
            {content}
          </Text>

          <Text variant="label" className="text-md md:text-lg my-6 text-yellow">
            {mediaLabel}
          </Text>

          <MediaPreview {...preview} />
        </div>

        <div>
          <div className="divide-y pt-2 divide-gray max-w-xl">
            {media?.map((m, i) => (
              <MediaCard {...m} key={i} />
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center mx-auto">
        <Button to={button?.url} size="md" color="transparent">
          <Text className="font-serif italic text-offwhite">
            {button?.text}
          </Text>
        </Button>
      </div>
    </div>
  );
};

TeachingsHome.defaultProps = {};

TeachingsHome.propTypes = {};

export default TeachingsHome;
