import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Button } from "@atoms";

const Filter = ({ text, slug, onClick, active }) => {
  const icon = {
    specialEvents: "specialEvents",
    recurringRituals: "om",
    talksPresentations: "star",
    performanceArts: "pray",
    classes: "classes",
    podcasts: "podcasts",
    videos: "videos",
    articles: "articles",
    audioTeachings: "audioTeachings",
  };
  return (
    <Button size="xxs" className="flex row" onClick={onClick}>
      {slug !== "all" && (
        <Icon
          name={icon[slug]}
          fitHeight
          className="w-3 h-3 mr-1.5 text-yellow self-center"
        />
      )}
      <Text
        className={`font-serif text-offwhite text-sm font-semibold tracking-wide 
          ${active ? "underline-1" : ""}`}
      >
        {text}
      </Text>
    </Button>
  );
};

Filter.defaultProps = {};

Filter.propTypes = {};

export default Filter;
