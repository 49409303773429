import moment from "moment-timezone";
import resolveImage from "./resolveImage";

const resolveTeaching = teaching => {
  const {
    title,
    teachingType,
    teachingExcerpt,
    teachingImage,
    metaDescription,
    metaImage,
    slug,
    metaTitle,
    postDate,
    showDate,
    mediaUrl,
    mediaEmbed,
    url,
  } = {
    ...teaching,
  };
  let label = "Read";
  switch (teachingType) {
    case "videos":
      label = "Watch";
      break;
    case "classes":
      label = "View";
      break;
    case "audioTeachings":
      label = "Hear";
      break;
    case "podcasts":
      label = "Listen";
      break;
    default:
      break;
  }
  return {
    title,
    slug,
    showDate,
    type: teachingType,
    excerpt: teachingExcerpt,
    mediaUrl,
    time: moment(postDate).tz("America/Los_Angeles").format("MMM Do, YYYY"),
    mediaEmbed,
    label,
    image: resolveImage(teachingImage),
    url,
    meta: {
      title: metaTitle || title,
      description: metaDescription,
      image: resolveImage(metaImage),
      url,
    },
  };
};

export default resolveTeaching;
