import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";

const CbHeading = ({ heading, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="cb-h2">{heading}</Text>
    </CbWrapper>
  );
};

CbHeading.defaultProps = {};

CbHeading.propTypes = {};

export default CbHeading;
