import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Button, Image } from "@atoms";
import { AppLink } from "@base";
import { titleCase } from "@utils";

const FeaturedCard = ({
  label,
  title,
  image,
  tag,
  time,
  description,
  link,
  showDate,
}) => {
  const icon = {
    "special-events": "specialEvents",
    "recurring-rituals": "om",
    "talks-presentations": "star",
    "performance-arts": "pray",
    classes: "classes",
    podcasts: "podcasts",
    videos: "videos",
    articles: "articles",
    audioTeachings: "audioTeachings",
  };
  return (
    <AppLink to={link?.url}>
      <div className="my-4">
        <Text
          variant="label"
          className="text-md md:text-lg md:mt-2 text-yellow tracking"
        >
          {label}
        </Text>
      </div>

      <div className="my-4 self-start w-full shrink">
        <Image {...image} aspectratio="8:5" />
      </div>

      <div className="mt-4">
        <Text variant="h5" className="text-offwhite font-bold">
          {title}
        </Text>
      </div>

      <div className="col-span-full flex flex-row my-2 items-center row-span-full">
        <Text className="text-yellow font-serif font-semibold text-sm pr-1 leading-tight">
          <span className="flex flex-row items-baseline">
            <Icon
              name={icon[tag?.slug]}
              fitHeight
              className="w-3 h-3 mr-1 text-yellow self-center"
            />
            <span className="capitalize">
              {titleCase(
                tag?.text === "talksPresentations"
                  ? "Talks/Presentations"
                  : tag?.text
              )}
            </span>
          </span>
        </Text>
        {showDate && (
          <>
            <span className="border-r mx-1 border-yellow block h-3" />
            <Text className="text-yellow font-serif font-semibold text-sm pl-1 leading-0">
              {time}
            </Text>
          </>
        )}
      </div>

      <div className="">
        <Text variant="body" className="text-offwhite text-md pr-6 mb-2">
          {description}
        </Text>
        <div className="w-full max-w-max">
          <span>
            <Text
              variant="body"
              className="underline-1--hover text-offwhite italic"
            >
              {link?.text}
            </Text>
          </span>
        </div>
      </div>
    </AppLink>
  );
};

FeaturedCard.defaultProps = {};

FeaturedCard.propTypes = {};

export default FeaturedCard;
