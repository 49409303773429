import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";

const CbList = ({ copy, indent, count, type }) => {
  return (
    <CbWrapper type={type} indent={indent} count={count}>
      <Text variant="body--large-tight">{copy}</Text>
    </CbWrapper>
  );
};
CbList.defaultProps = {};

CbList.propTypes = {};

export default CbList;
