import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Button, Icon } from "@atoms";

const TimelineCard = ({ step, metric, description, active }) => {
  const level = {
    1: "text-purple",
    2: "text-red",
    3: "text-timeline-orange",
    4: "text-yellow",
  };

  const romanize = num => {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };
    let numb = num;
    let roman = "";

    for (const i of Object.keys(romanNumerals)) {
      while (numb >= romanNumerals[i]) {
        roman += i;
        numb -= romanNumerals[i];
      }
    }
    return roman;
  };

  return (
    <div
      className={`
      border border-yellow border-opacity-50 md:max-w-timeline w-full py-4 text-center
      ${active ? "border-opacity-100 bg-white bg-opacity-12" : ""}`}
    >
      <div className="px-6">
        <Text variant="h2" className={`${level[step]}`}>
          {romanize(step)}
        </Text>

        <Text variant="h6" className={`${level[step]} text-3xl font-bold mb-4`}>
          {metric}
        </Text>

        <Text
          variant="body"
          className="font-serif text-offwhite text-md md:leading-snug"
        >
          {description}
        </Text>
      </div>
    </div>
  );
};

TimelineCard.defaultProps = {};

TimelineCard.propTypes = {};

export default TimelineCard;
