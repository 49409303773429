import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text } from "@atoms";
import { AppLink } from "@base";

const ReservationsHome = ({ heading, content, link }) => {
  return (
    <div className="text-center flex flex-col py-12 md:pb-20 px-4 mx-auto w-full max-w-md">
      <Text
        variant="h5"
        className="text-yellow font-serif font-bold text-2xl md:text-4xl"
      >
        {heading}
      </Text>

      <Text className="text-offwhite font-serif my-6 leading-snug">
        {content}
      </Text>

      <AppLink to={link?.url}>
        <Text className="text-offwhite font-semibold leading-tight font-serif underline-1--hover italic tracking-wider max-w-max mx-auto">
          {link?.text}
        </Text>
      </AppLink>
    </div>
  );
};

ReservationsHome.defaultProps = {};

ReservationsHome.propTypes = {};

export default ReservationsHome;
