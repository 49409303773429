import React from "react";
import propTypes from "prop-types";
import { useFooterData } from "@staticQueries";
import { Icon, Container, Image, Button } from "@atoms";
import { Newsletter, CommunityHome } from "@molecules";
import { AppLink } from "@base";
import classNames from "classnames";
import SupportHome from "./SupportHome";

const Footer = React.memo(
  ({ showSupport, showNewsletter, showCommunity, hidePhotos, background }) => {
    const { links, social, images } = useFooterData();

    return (
      <footer
        className={classNames(
          "flex flex-col w-full  text-white py-12 font-serif font-semibold relative",
          { background, "bg-blue": !background }
        )}
      >
        <div className="p-6 relative z-10">
          {showNewsletter && (
            <div className="mb-12 md:mb-20">
              <Newsletter />
            </div>
          )}
          {showSupport && (
            <div className="mb-12 md:mb-20">
              <SupportHome />
            </div>
          )}
          {showCommunity && (
            <div className="mb-12 md:mb-20">
              <CommunityHome />
            </div>
          )}
        </div>
        {!hidePhotos && (
          <div className="absolute inset-0 z-0">
            <div
              className="absolute inset-0 z-10 opacity-50"
              style={{ background: "#000000" }}
            />
            <div className="absolute inset-0 z-0">
              <Image
                {...images[Math.floor(Math.random() * images.length)]}
                params={{ exp: -5 }}
                fill
              />
            </div>
          </div>
        )}
        <Container className="relative z-10">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/4 text-sm  italic px-3 mb-6">
              <AppLink to="/">
                <Icon name="logo" className="h-12" fitHeight />
              </AppLink>
              {/* <AppLink to="/privacy" className="block mt-6">
              Privacy Policy
            </AppLink> */}
            </div>
            <div className="w-full md:w-3/4 max-w-lg ml-auto px-3 mb-6">
              <div className="flex flex-col sm:flex-row flex-wrap italic -mx-3 text-white">
                {links &&
                  links.map(link => {
                    if (link.style === "button") {
                      return (
                        <div className="w-1/3 mb-6 px-3 flex order-3 sm:order-none">
                          <Button color="transparent" to={link.url} size="xs">
                            {link.label}
                          </Button>
                        </div>
                      );
                    }
                    return (
                      <AppLink
                        to={link.url}
                        className={classNames(
                          "text-left w-1/3 mb-6 px-3 sm:order-none",
                          {
                            "order-1 font-semibold text-lg":
                              link.type === "entry" ||
                              link.type === "url" ||
                              link.type === "custom",
                            "order-2 text-sm font-semibold":
                              link.type !== "entry" &&
                              link.type !== "url" &&
                              link.type !== "custom",
                          }
                        )}
                      >
                        {link.label}
                      </AppLink>
                    );
                  })}
                <div className="w-1/3 mb-6 px-3 order-9">
                  <div className="flex -mx-2">
                    {social &&
                      social.map(link => {
                        switch (link.service) {
                          case "facebook":
                            return (
                              <AppLink
                                to={link.url}
                                className="flex items-center justify-center rounded-full border border-white-alpha h-6 w-6 mr-3"
                              >
                                <svg
                                  className="fill-white w-3 h-3"
                                  viewBox="0 0 12 21"
                                >
                                  <path d="M10.6484 11.75H7.71875V20.5H3.8125V11.75H0.648438V8.11719H3.8125V5.38281C3.8125 4.34115 4.00781 3.45573 4.39844 2.72656C4.78906 1.9974 5.33594 1.45052 6.03906 1.08594C6.76823 0.695312 7.60156 0.5 8.53906 0.5C8.95573 0.5 9.39844 0.526042 9.86719 0.578125C10.3359 0.604167 10.7005 0.643229 10.9609 0.695312L11.3516 0.734375V3.82031H9.78906C9.0599 3.82031 8.52604 4.01562 8.1875 4.40625C7.875 4.77083 7.71875 5.22656 7.71875 5.77344V8.11719H11.1953L10.6484 11.75Z" />
                                </svg>
                              </AppLink>
                            );
                          case "youtube":
                            return (
                              <AppLink
                                to={link.url}
                                className="flex items-center justify-center rounded-full border border-white-alpha h-6 w-6 mr-3"
                              >
                                <svg
                                  className="fill-white w-3 h-3"
                                  viewBox="0 0 192 192"
                                >
                                  <path
                                    className="st0"
                                    d="M180.3,53.4c-2-7.6-8-13.6-15.6-15.7C151,34,96,34,96,34s-55,0-68.8,3.7c-7.6,2-13.5,8-15.6,15.7
                      C8,67.2,8,96,8,96s0,28.8,3.7,42.6c2,7.6,8,13.6,15.6,15.7C41,158,96,158,96,158s55,0,68.8-3.7c7.6-2,13.5-8,15.6-15.7
                      C184,124.8,184,96,184,96S184,67.2,180.3,53.4z M78,122.2V69.8L124,96L78,122.2z"
                                  />
                                </svg>
                              </AppLink>
                            );
                          default:
                            return null;
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    );
  }
);

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
