import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Button } from "@atoms";
import { HeadingWithCopy, EventCard } from "@molecules";

const EventHome = ({ title, content, events, button }) => {
  return (
    <>
      <HeadingWithCopy heading={title} headingColor="blue" iconColor="orange">
        <div className="text-blue font-serif eading-snug font-bold mb-4 w-full max-w-md mx-auto">
          <Text>{content}</Text>
        </div>
      </HeadingWithCopy>

      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 justify-center mb-10 px-4">
        {events?.map((card, i) => (
          <EventCard {...card} key={i} />
        ))}
      </div>

      {button && (
        <div className="flex flex-nowrap justify-center">
          <Button to={button.url} color="yellow" size="md">
            <Text variant="body" className="font-serif italic">
              {button.text}
            </Text>
          </Button>
        </div>
      )}
    </>
  );
};

EventHome.defaultProps = {};

EventHome.propTypes = {};

export default EventHome;
