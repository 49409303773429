import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper, Button } from "@atoms";
import { AppLink } from "@base";

const CbLink = ({ link, indent, margin, type }) => {
  return (
    <CbWrapper type={type} indent={indent} margin={margin}>
      <Button color="yellow" to={link.url}>
        {/* label */}
        {link.text}
      </Button>
    </CbWrapper>
  );
};

CbLink.defaultProps = {};

CbLink.propTypes = {};

export default CbLink;
