import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { mandala } from "@images";

const Mandala = ({ position }) => {
  let classes = "";
  let containerClasses = "";
  switch (position) {
    case "hero":
      containerClasses = "-bottom-28 md:-bottom-48";
      classes = "-right-28 md:-right-52 z-30";
      break;
    case "center-left":
      containerClasses = "bottom-0 top-0";
      classes = "-left-28 md:-left-52 right-0 my-auto bg-center z-0";
      break;
    default:
      containerClasses = "bottom-0 top-0";
      classes = "left-0 right-0 m-auto bg-center z-0";
      break;
  }
  return (
    <div
      className={`pointer-events-none absolute left-0 right-0 h-60 md:h-mandala overflow-x-hidden ${containerClasses}`}
    >
      <div
        className={`pointer-events-none w-60 h-60 md:h-mandala md:w-mandala absolute bg-cover ${classes}`}
        style={{ backgroundImage: `url('${mandala}')` }}
      />
    </div>
  );
};

Mandala.defaultProps = {};

Mandala.propTypes = {};

export default Mandala;
