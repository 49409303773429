import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const data = {
  heading: "A Mountaintop Community",
  content:
    "The Temple is a part of the Mount Madonna Center which is also home to the Mount Madonna Institute and a host of related projects.",
  relatedProjects: [
    {
      url: "https://www.mountmadonna.org/",
      image: {
        url:
          "https://s3.us-west-1.amazonaws.com/hanuman-uploads/uploads/logo-mm-center.png",
        width: 104,
        height: 57,
      },
    },
    {
      url: "https://www.mountmadonnainstitute.org/",
      image: {
        url:
          "https://s3.us-west-1.amazonaws.com/hanuman-uploads/uploads/logo-mm-institute.png",
        width: 168,
        height: 48,
      },
    },
  ],
};

const CommunityHome = () => {
  const { heading, content, relatedProjects } = data;
  return (
    <div className="bg-black bg-opacity-55 text-center py-8 px-4  w-full max-w-screen-lg mx-auto border border-yellow">
      <div className="flex flex-col w-full max-w-screen-md mx-auto">
        <Text
          variant="h5"
          className="text-yellow font-serif font-bold text-2xl md:text-4xl"
        >
          {heading}
        </Text>

        <Text className="text-offwhite font-serif my-6 leading-snug">
          {content}
        </Text>
      </div>

      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-16 justify-center">
        {relatedProjects?.map((logo, i) => (
          <AppLink
            to={logo.url}
            className="flex-grow-0 w-full max-w-community-logo mx-auto md:mx-0 md:h-12"
          >
            <Image {...logo?.image} key={i} fitHeight placeholder={false} />
          </AppLink>
        ))}
      </div>
    </div>
  );
};

CommunityHome.defaultProps = {};

CommunityHome.propTypes = {};

export default CommunityHome;
