import { useStaticQuery, graphql } from "gatsby";
import { resolveImage, mergeCraftPreview } from "@dataResolvers";

// define the query
const query = graphql`
  # the query name needs to be unique
  query NewsletterBlurbQuery {
    craft {
      globalSet(handle: "newsletterBlock") {
        ... on Craft_newsletterBlock_GlobalSet {
          newsletterBlurb
        }
      }
    }
  }
`;

const previewQuery = ``;

// define the data resolver
// destructure the query response in the props declaration
const dataResolver = ({ craft }) => {
  // destructure the content from the craft object
  const { newsletterBlurb } = craft.globalSet;

  // now build the data object we're passing to the page template
  return { content: newsletterBlurb, button: { text: "Sign Up" } };
};

// define custom hook
const useData = () => {
  // get the initial query
  const response = useStaticQuery(query);
  // merge data with previewData, if there is any
  const data = mergeCraftPreview(response, previewQuery);
  // use the dataresolve defined above to transform the merged query responses
  return dataResolver(data);
};

// export custom hook
export default useData;
