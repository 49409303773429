// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-craft-dynamic-queries-about-query-js": () => import("./../../../src/craft/dynamicQueries/AboutQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-about-query-js" */),
  "component---src-craft-dynamic-queries-covid-19-query-js": () => import("./../../../src/craft/dynamicQueries/Covid19Query.js" /* webpackChunkName: "component---src-craft-dynamic-queries-covid-19-query-js" */),
  "component---src-craft-dynamic-queries-event-query-js": () => import("./../../../src/craft/dynamicQueries/EventQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-event-query-js" */),
  "component---src-craft-dynamic-queries-events-query-js": () => import("./../../../src/craft/dynamicQueries/EventsQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-events-query-js" */),
  "component---src-craft-dynamic-queries-home-query-js": () => import("./../../../src/craft/dynamicQueries/HomeQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-home-query-js" */),
  "component---src-craft-dynamic-queries-teaching-query-js": () => import("./../../../src/craft/dynamicQueries/TeachingQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-teaching-query-js" */),
  "component---src-craft-dynamic-queries-teachings-query-js": () => import("./../../../src/craft/dynamicQueries/TeachingsQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-teachings-query-js" */),
  "component---src-craft-dynamic-queries-thank-you-query-js": () => import("./../../../src/craft/dynamicQueries/ThankYouQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-thank-you-query-js" */),
  "component---src-craft-dynamic-queries-visit-query-js": () => import("./../../../src/craft/dynamicQueries/VisitQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-visit-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-form-js": () => import("./../../../src/pages/DonateForm.js" /* webpackChunkName: "component---src-pages-donate-form-js" */)
}

