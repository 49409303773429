import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Button, Image } from "@atoms";
import { AppLink } from "@base";

const PostContent = ({ title, image, tag, time, showDate }) => {
  const icon = {
    specialEvents: "specialEvents",
    recurringRituals: "om",
    talksPresentations: "star",
    performanceArts: "pray",
    classes: "classes",
    podcasts: "podcasts",
    videos: "videos",
    articles: "articles",
    audioTeachings: "audioTeachings",
  };
  return (
    <>
      {image && (
        <div className="my-4 self-start w-full shrink">
          <Image {...image} aspectratio="8:5" />
        </div>
      )}

      <div className="mt-4">
        <Text variant="h5" className="text-teal font-bold">
          {title}
        </Text>
      </div>

      <div className="col-span-full flex flex-row my-2 items-center row-span-full">
        <Text className="text-orange font-serif font-semibold text-sm pr-1 leading-tight">
          <span className="flex flex-row items-baseline">
            <Icon
              name={icon[tag?.slug]}
              fitHeight
              className="w-3 h-3 mr-1 text-orange self-center"
            />
            <span className="capitalize">
              {tag?.text === "talksPresentations"
                ? "Talks/Presentations"
                : tag?.text}
            </span>
          </span>
        </Text>
        {showDate && (
          <>
            <span className="border-r mx-1 border-orange block h-3" />
            <Text className="text-orange font-serif font-semibold text-sm pl-1 leading-0">
              {time}
            </Text>
          </>
        )}
      </div>
    </>
  );
};

PostContent.defaultProps = {};

PostContent.propTypes = {};

export default PostContent;
