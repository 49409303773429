import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";
import { AppLink } from "@base";

const CbLink = ({ link, indent, margin, type }) => {
  return (
    <CbWrapper type={type} indent={indent} margin={margin}>
      <AppLink to={link.url} className="inline-flex items-center">
        {/* label */}
        <span className="font-serif underline-1-rose--hover font-semibold leading-tight italic tracking-wider">
          {link.text}
        </span>
      </AppLink>
    </CbWrapper>
  );
};

CbLink.defaultProps = {};

CbLink.propTypes = {};

export default CbLink;
