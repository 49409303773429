import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Image } from "@atoms";
import { AppLink } from "@base";

const EventCard = ({ title, time, repeats, content, image, url }) => {
  return (
    <AppLink
      className="shadow-md bg-white items-center justify-start w-full md:max-w-event-card flex flex-col transform hover:scale-105 transition duration-500 hover:shaddow-xl"
      to={url}
    >
      <Image {...image} aspectratio="4:3" />

      <div className="p-4 space-y-2 flex flex-col text-center">
        <Text
          variant="h6"
          className="text-teal font-serif font-bold text-xl md:text-2xl"
        >
          {title}
        </Text>
        <Text className="text-orange font-serif font-bold text-sm">{time}</Text>
        <Text className="text-blue font-serif leading-tight font-bold text-sm">
          {content}
        </Text>
      </div>
    </AppLink>
  );
};

EventCard.defaultProps = {};

EventCard.propTypes = {};

export default EventCard;
