import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Button, Field } from "@atoms";
import { useNewsletterData } from "@staticQueries";
import gsap from "gsap";
import axios from "axios";

const Newsletter = ({ color, small }) => {
  const { content, field, button } = useNewsletterData();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const form = useRef();
  const errorMessage = useRef();
  const successMessage = useRef();
  useEffect(() => {
    if (success) {
      gsap.to(successMessage.current, {
        opacity: 1,
        duration: 0.2,
        display: "flex",
        // easing: "back",
      });
      gsap.to(form.current, { opacity: 0, pointerEvents: "none" });
    }
  }, [success]);

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  const submitForm = () => {
    if (formData && !success) {
      axios
        .post(`/.netlify/functions/mailchimp`, JSON.stringify(formData), {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then(res => {
          if (!res.data.error) {
            // this.$gtm.push({ event: "emailSignUp" });
            setSuccess(true);
            return;
          }
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        });
    } else {
      setError("Please fill out all required fields");
    }
  };
  const colors = {
    orange: "bg-orange",
    yellow: "bg-yellow",
    rose: "bg-rose",
    blue: "bg-teal",
    brown: "bg-brown",
    teal: "bg-teal",
    white: "bg-white",
    black: "bg-black",
    offwhite: "bg-offwhite",
    gray: "bg-gray",
    bluegray: "bg-tealgray",
  };
  return (
    <div
      className={`w-full mx-auto relative
      ${color ? colors[color] : "bg-offwhite bg-opacity-12"}
      ${small ? "sm:max-w-xs hidden md:block" : "max-w-screen-lg p-2"}`}
    >
      <div
        className={`p-6
        ${color ? "" : "border border-yellow"} ${small ? "" : "pb-10"}`}
      >
        <div className="flex justify-between items-start mb-4 md:mb-0">
          {!small && (
            <>
              <Icon name="flower" className="text-yellow w-16 h-16" />
              <Icon name="newsletter" className="text-yellow w-40" />
              <Icon name="flowerFlipped" className="text-yellow w-16 h-16" />
            </>
          )}
          {small && (
            <Icon
              name="ramFlowers"
              className="text-yellow h-6 w-20 mx-auto mb-4"
            />
          )}
        </div>

        <Text
          variant="h6"
          className={`font-serif text-offwhite text-center font-bold w-full max-w-md mx-auto -mt-2
          ${small ? "text-md md:text-xl" : "text-xl md:text-2xl"}`}
        >
          {content}
        </Text>

        <div
          ref={errorMessage}
          className="bg-yellow mt-2 -mb-2 h-0 overflow-hidden"
        >
          <div className="p-3">{error}</div>
        </div>
        <div
          ref={successMessage}
          className="absolute text-center border border-yellow text-lg flex flex-col inset-0 bg-teal text-white justify-center items-center p-3 pointer-events-none z-10 hidden opacity-0"
        >
          <Icon
            name="ramFlowers"
            className="text-yellow h-6 w-20 mx-auto mb-4"
          />
          You have successfully subscribed to updates.
        </div>

        <form
          className={`flex justify-center items-start mt-4 justify-items-auto
          ${
            small
              ? "flex-col space-y-6"
              : "px-2 md:px-8 flex-col sm:flex-row space-y-6 sm:space-y-0 w-full max-w-md mx-auto space-x-6 md:space-x-12"
          }`}
          onSubmit={e => {
            e.preventDefault();
            submitForm();
          }}
        >
          <Field
            {...field}
            name="email"
            type="email"
            placeholder="email"
            className="flex-grow w-full max-w-sm"
            onChange={e => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <div
            className={classNames("flex-shrink-0 relative z-0", {
              "self-center": small,
              "pointer-events-none cursor-not-allowed": !formData.email?.length,
            })}
          >
            <Button type="submit" color="yellow" size="md">
              <Text variant="body" className="font-serif italic">
                {button?.text}
              </Text>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

Newsletter.defaultProps = {};

Newsletter.propTypes = {};

export default Newsletter;
