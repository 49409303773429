import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Button, Image } from "@atoms";
import { AppLink } from "@base";
import { titleCase } from "@utils";

const UpcomingCard = ({
  label,
  title,
  image,
  tag,
  time,
  description,
  link,
  inverse,
  showDate,
}) => {
  const icon = {
    specialEvents: "specialEvents",
    recurringRituals: "om",
    talksPresentations: "star",
    performanceArts: "pray",
    classes: "classes",
    podcasts: "podcasts",
    videos: "videos",
    articles: "articles",
    audioTeachings: "audioTeachings",
  };
  if (title?.length) {
    return (
      <div>
        <div className="my-4">
          <Text
            variant="h6"
            className={`text-md md:text-lg md:mt-2 font-bold 
            ${inverse ? "text-teal" : "text-yellow"}`}
          >
            {label || "Upcoming Event"}
          </Text>
        </div>

        <div className="my-4 self-start w-full shrink">
          <Image {...image} fitHeight />
        </div>

        <div className="mt-4">
          <Text
            variant="body"
            className={`${
              inverse ? "text-teal" : "text-offwhite"
            } text-lg pr-6 mb-2`}
          >
            {title}
          </Text>
        </div>

        <div className="col-span-full flex flex-col my-2 items-start leading-tight row-span-full">
          <Text
            className={`font-serif font-semibold text-sm pr-1 leading-tight 
          ${inverse ? "text-orange" : "text-yellow"}`}
          >
            <span className="flex flex-row items-baseline">
              <Icon
                name={icon[tag?.slug]}
                fitHeight
                className={`w-3 h-3 mr-1 self-center ${
                  inverse ? "text-orange" : "text-yellow"
                }`}
              />
              <span className="capitalize">
                {tag?.text === "talksPresentations"
                  ? "Talks/Presentations"
                  : titleCase(tag?.text)}
              </span>
            </span>
          </Text>
          {showDate && (
            <Text
              className={`font-serif font-semibold text-sm pl-1 
          ${inverse ? "text-orange" : "text-yellow"}`}
            >
              {time}
            </Text>
          )}
        </div>

        <div>
          <Text
            variant="body--sm"
            className={`text-sm font-serif font-semibold pr-6 mb-2 
            ${inverse ? "text-rose" : "text-offwhite"}`}
          >
            {description}
          </Text>
          <div className="w-full max-w-max">
            <AppLink to={link?.url}>
              <Text
                variant="body"
                className={`italic 
                ${
                  inverse
                    ? "underline-1-rose text-rose"
                    : "underline-1--hover text-offwhite"
                }`}
              >
                {link?.text}
              </Text>
            </AppLink>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

UpcomingCard.defaultProps = {};

UpcomingCard.propTypes = {};

export default UpcomingCard;
