/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react";
import "../../pcss/video.pcss";
import Image from "./Image";

const Video = ({ url, play, type }) => {
  if (typeof window !== "undefined") {
    const plyr = useRef();
    const player = useRef();

    useEffect(() => {
      if (player.current) {
        if (play) {
          player.current.play();
        } else {
          player.current.pause();
        }
      }
    }, [play]);

    // eslint-disable-next-line global-require
    const Plyr = require("plyr");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setTimeout(() => {
        if (!player.current) {
          if (plyr.current && Plyr) {
            player.current = new Plyr(plyr.current);
          }
        }
      }, 500);
    }, []);
    // set up the player
    return (
      <audio ref={plyr}>
        <source src={url} type={`audio/${type}`} />
      </audio>
    );
  }
  return null;
};

Video.defaultProps = {
  play: false,
  type: "mp3",
};

export default Video;
