import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { AppLink } from "@base";
import { Image, Text, Button, Container, Icon } from "@atoms";

const HeroCard = ({ title, description, image, url, scrimOpacity }) => {
  return (
    <div
      className="w-11/12 md:w-full max-w-full md:max-w-card max-h-full relative shadow shadow-md my-2 mx-4 md:mx-2 md:my-0"
      style={{ textShadow: "0 0 20px black" }}
    >
      <AppLink
        to={url}
        className="flex flex-col justify-items-stretch w-full h-full py-3 sm:py-0 group relative transition duration-200 transform hover:scale-105"
      >
        <div className="z-10 absolute top-0 left-0 w-full h-full">
          <Image {...image} fill params={{ exp: -1 }} />
        </div>

        <div
          className="absolute opacity-50 bottom-0 h-4/6 w-full bg-gradient-to-t from-black z-10 transition duration-500"
          style={{ opacity: scrimOpacity }}
        />
        <div
          className="block sm:hidden absolute inset-0 bg-black opacity-50 z-10 transition duration-500"
          style={{ opacity: scrimOpacity / 2 }}
        />

        <div className="p-3 md:p-5 md:pt-60 md:px-6 box-border relative z-20 flex flex-col align-center justify-center items-center">
          <Text
            variant="h5"
            className="font-serif text-center text-yellow mb-2 md:mb-4 text-3xl md:text-4xl font-bold"
          >
            {title}
          </Text>

          <Text
            variant="h7"
            className="font-serif text-center text-offwhite mb-2 md:mb-4 fold-bold"
          >
            {description}
          </Text>

          <Icon name="om" className="text-yellow w-4 h-4 justify-self-center" />
        </div>
      </AppLink>
    </div>
  );
};

HeroCard.defaultProps = {};

HeroCard.propTypes = {};

export default HeroCard;
