import React from "react";
import propTypes from "prop-types";
import GatsbyLink from "gatsby-plugin-transition-link";
import { useAppState } from "@state/state";
import gsap from "gsap";

const AppLink = ({
  to: _url,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
  style,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();
  const tTime = 500;

  const handleExit = ({ exit, node }) => {
    dispatch({
      type: "startTransition",
    });
    gsap.fromTo(
      node,
      { opacity: 1 },
      {
        opacity: 0,
        duration: tTime / 1000,
        ease: "Power1.easeOut",
      }
    );
  };

  const handleEntry = ({ entry, node }) => {
    gsap.fromTo(
      node,
      { opacity: 0 },
      {
        opacity: 1,
        duration: tTime / 1000,
        ease: "Power1.easeIn",
        onComplete: () => {
          const dataLayer = window.dataLayer || [];
          if (typeof window !== "undefined") {
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        },
      }
    );
  };

  if (_url && typeof _url === "string") {
    const craftUrl = process.env.GATSBY_CRAFT_BASE_URL;
    const siteUrl = process.env.GATSBY_SITE_URL;
    let url = _url.replace(craftUrl, "").replace(siteUrl, "");

    const handleClick = e => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
      // Trigger page transition
      dispatch({
        type: "startTransition",
        to: url,
      });
    };

    // handle internal links
    // usually internal router and/or transition
    if (
      url.includes(craftUrl) ||
      url.includes(siteUrl) ||
      (!url.includes("https://") &&
        !url.includes("http://") &&
        !url.includes("mailto:") &&
        !url.includes("tel:"))
    ) {
      // Add a preceding slash to any relative urls
      if (url.indexOf("http") === -1 && url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      return (
        <GatsbyLink
          className={`
            cursor-pointer 
            ${_className} 
            ${
              typeof window !== "undefined" && window.location.pathname === url
                ? "pointer-events-none"
                : ""
            }
          `}
          // activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive
          title={title}
          name={title}
          to={url}
          onClick={handleClick}
          style={style}
          exit={{
            zIndex: 0,
            length: tTime / 1000,
            trigger: handleExit,
          }}
          entry={{
            zIndex: 1,
            length: tTime / 1000,
            delay: 0.5,
            // eslint-disable-next-line no-shadow
            trigger: handleEntry,
          }}
        >
          {children}
        </GatsbyLink>
      );
    }
    if (url.includes(process.env.GATSBY_OTHER_SITE)) {
      return (
        <a
          href={url || ""}
          title={title}
          name={title}
          className={`${_className || ""} cursor-pointer`}
          onClick={onClick}
          style={style}
        >
          {children}
        </a>
      );
    }
    // handle external links
    // usually open in new tab, noreferer, noopener
    return (
      <a
        href={url || ""}
        title={title}
        name={title}
        target="_blank"
        rel="noopener noreferrer"
        className={`${_className || ""} cursor-pointer`}
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${_className || ""} cursor-pointer`}
      style={style}
    >
      {children}
    </button>
  );
};

AppLink.defaultProps = {
  to: null,
  title: null,
  className: null,
  onClick: null,
  activeClassName: null,
};

AppLink.propTypes = {
  to: propTypes.string,
  title: propTypes.string,
  className: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  onClick: propTypes.func,
  activeClassName: propTypes.string,
};

export default AppLink;
