import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper, Image } from "@atoms";

const CbImage = ({ image, caption, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="shadow-xl">
        <Image {...image} />
      </div>
      {image.caption && (
        <Text variant="caption" className="mt-4">
          {image.caption}
        </Text>
      )}
    </CbWrapper>
  );
};

CbImage.defaultProps = {};

CbImage.propTypes = {};

export default CbImage;
