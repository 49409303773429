import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { useHeaderData } from "@staticQueries";
import { AppLink } from "@base";
import { Container, Icon } from "@atoms";
import { HeaderNav, MobileNav } from "@molecules";
import { useAppState } from "@state/state";

const Header = ({ colored }) => {
  const [{ layout }, dispatch] = useAppState();
  const { theme } = layout;
  const { nav } = useHeaderData();
  return (
    <div className="z-50 py-2 h-20 absolute top-0 left-0 right-0">
      <Container className="h-full">
        <div className="flex h-full items-center w-full justify-between">
          <div>
            <AppLink to="/">
              <Icon name="logo" className="text-white h-10" fitHeight />
            </AppLink>
          </div>
          <div className="hidden md:flex">
            <HeaderNav links={nav} />
          </div>
          <button
            type="button"
            className="flex md:hidden"
            onClick={() => {
              dispatch({ type: "showNav" });
            }}
          >
            <Icon
              name="bars"
              className={classNames(
                "text-white hover:text-yellow w-6 h-6 transform scale-y-110 transition duration-500",
                {}
              )}
            />
          </button>
        </div>
      </Container>
      <MobileNav links={nav} />
    </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
