/* eslint-disable no-console */
/* eslint-disable no-unreachable */
import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Audio as AudioPlayer, Video } from "@atoms";

const MediaLink = ({ url }) => {
  const isVideo = url.match("http(s)?://(www.)?youtube|youtu.be|vimeo");
  if (typeof window !== "undefined") {
    if (isVideo) {
      return (
        <div className="my-8">
          <Video url={url} />
        </div>
      );
    }
    return (
      <div className="my-8">
        <AudioPlayer url={url} />
      </div>
    );
  }
  return <div />;
};

MediaLink.defaultProps = {};

MediaLink.propTypes = {};

export default MediaLink;
