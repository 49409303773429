import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";

const CbCopy = ({ copy, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="lg">{copy}</Text>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

CbCopy.propTypes = {};

export default CbCopy;
