import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";

const CbLeadText = ({ copy, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="xl">{copy}</Text>
    </CbWrapper>
  );
};

CbLeadText.defaultProps = {};

CbLeadText.propTypes = {};

export default CbLeadText;
