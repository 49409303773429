import { useStaticQuery, graphql } from "gatsby";
import { resolveImage, mergeCraftPreview } from "@dataResolvers";

// define the query
const query = graphql`
  # the query name needs to be unique
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          imageGallery {
            ... on Craft_imageGallery_image_BlockType {
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          nav {
            ... on Craft_nav_link_BlockType {
              uid
              navlink {
                url
                text
                type
              }
              style
            }
          }
          socialLinks {
            ... on Craft_socialLinks_link_BlockType {
              service
              socialurl
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

// define the data resolver
// destructure the query response in the props declaration
const dataResolver = ({ craft }) => {
  // destructure the content from the craft object
  const { nav, socialLinks, imageGallery } = craft.globalSet;

  // now build the data object we're passing to the page template
  return {
    images: imageGallery.map(i => resolveImage(i.image)),
    links: nav.map(link => {
      return {
        label: link.navlink.text,
        url: link.navlink.url,
        style: link.style,
        type: link.navlink.type,
      };
    }),
    social: socialLinks.map(link => {
      return {
        service: link.service,
        url: link.socialurl,
      };
    }),
  };
};

// define custom hook
const useData = () => {
  // get the initial query
  const response = useStaticQuery(query);
  // merge data with previewData, if there is any
  const data = mergeCraftPreview(response, previewQuery);
  // use the dataresolve defined above to transform the merged query responses
  return dataResolver(data);
};

// export custom hook
export default useData;
