import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          nav {
            ... on Craft_nav_link_BlockType {
              uid
              navlink {
                url
                text
              }
              style
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { nav } = craft.globalSet;
  return {
    nav: nav.map(link => {
      return {
        uid: link.uid,
        link: link.navlink,
        style: link.style,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
