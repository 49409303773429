import React from "react";
import propTypes from "prop-types";
import {
  CbBlockQuote,
  CbCopy,
  CbHeading,
  CbImage,
  CbLeadText,
  CbLink,
  CbList,
  CbSubheading,
  CbVideo,
  CbButton,
} from "@molecules";

const ContenBuilder = ({ blocks }) => {
  let listCount = 0;
  return (
    <div className="content-builder">
      {blocks &&
        blocks.map(block => {
          switch (block.type) {
            case "blockQuote":
              return <CbBlockQuote {...block} />;
            case "copy":
              return <CbCopy {...block} />;
            case "heading":
              return <CbHeading {...block} />;
            case "image":
              return <CbImage {...block} />;
            case "leadText":
              return <CbLeadText {...block} />;
            case "link":
              return <CbLink {...block} />;
            case "button":
              return <CbButton {...block} />;
            case "list":
              if (block.restarCount) {
                listCount = 0;
              }
              listCount += 1;
              return <CbList {...block} count={listCount} />;
            case "subheading":
              return <CbSubheading {...block} />;
            case "video":
              return <CbVideo {...block} />;
            default:
              return null;
          }
        })}
    </div>
  );
};

ContenBuilder.defaultProps = {};

ContenBuilder.propTypes = {};

export default ContenBuilder;
