import React from "react";
import propTypes from "prop-types";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
}) => {
  let Tag = "div";
  let classes = "text-base";
  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "font-serif font-bold text-4xl lg:text-6xl leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes = "text-3xl font-bold text-teal leading-tight";
      break;
    case "h2--yellow":
      Tag = "h2";
      classes = "text-3xl font-bold text-yellow leading-tight";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-serif text-3xl md:text-5xl leading-tighter";
      break;
    case "cb-h2":
      Tag = "h2";
      classes = "text-3xl font-bold text-teal font-serif leading-tight";
      break;
    case "cb-h3":
      Tag = "h3";
      classes = "text-xl leading-tight text-teal font-sans font-bold";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-serif text-1-5xl leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-serif text-2xl md:text-3xl leading-tighter";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-serif text-1-5xl leading-tighter";
      break;
    case "h7":
      Tag = "h7";
      classes = "font-serif text-lg md:text-xl leading-tighter";
      break;
    case "xl":
      classes = "block text-xl leading-normal font-serif";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-serif";
      break;
    case "lg--tight":
      classes = "block text-lg leading-tight font-serif";
      break;
    case "body":
      classes = "block text-base leading-normal font-serif";
      break;
    case "body--tight":
      classes = "block text-base leading-tight font-serif";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-serif";
      break;
    case "caption":
      classes = "block text-sm leading-normal italic font-serif";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-xs font-semibold leading-none font-sans uppercase tracking-wider";
      break;
    default:
      classes = "";
      break;
  }
  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          key={i}
          className={`
            ${classes} 
            ${_className} 
            ${!richText ? "" : "rich-text"} 
            ${quotes ? "quotes" : ""} 
            `}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        className={`
          ${classes} 
          ${_className} 
          ${!richText ? "" : "rich-text"} 
          ${quotes ? "quotes" : ""} 
        `}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: true,
  quotes: false,
  children: null,
};

Text.propTypes = {
  className: propTypes.string,
  tag: propTypes.string,
  variant: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
  richText: propTypes.bool,
};

export default Text;
