import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper, Video } from "@atoms";

const CbVideo = ({ videoUrl, caption, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="shadow-xl">
        <Video url={videoUrl} />
      </div>
      {caption && (
        <Text variant="caption" className="mt-4">
          {caption}
        </Text>
      )}
    </CbWrapper>
  );
};

CbVideo.defaultProps = {};

CbVideo.propTypes = {};

export default CbVideo;
