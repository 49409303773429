import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { gradientPattern } from "@images";

const TemplatePattern = ({ flip }) => {
  return (
    <div
      className={`pointer-events-none h-32 bg-repeat-x bg-pattern w-full absolute left-0 right-0 z-30 
      ${flip ? "flip-y top-0" : "bottom-0"}`}
      style={{ backgroundImage: `url('${gradientPattern}')` }}
    />
  );
};

TemplatePattern.defaultProps = {};

TemplatePattern.propTypes = {};

export default TemplatePattern;
