/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React from "react";
import "./src/pcss/index.pcss";
import wrapWithState from "@state/wrapWithState";

export const wrapRootElement = wrapWithState;

export const wrapPageElement = ({ element }) => {
  return <>{element}</>;
};
