module.exports = {
  purge:
    process.env.NODE_ENV === "development"
      ? false
      : {
          mode: "all",
          content: ["./src/**/*.{js,svg}"],
          options: {
            safelist: ["object-contain", "object-cover", "w-1/1"],
          },
        },
  theme: {
    // fonts
    fontFamily: {
      sans: ["Josefin Sans", "sans-serif"],
      serif: ["Cormorant Garamond", "Georgia", "serif"],
    },
    // brand colors
    colors: {
      orange: {
        DEFAULT: "#C45019",
      },
      yellow: {
        DEFAULT: "#FDB14A",
      },
      rose: {
        DEFAULT: "#8E4A4A",
      },
      blue: {
        DEFAULT: "#1C334A",
      },
      brown: {
        DEFAULT: "#260C0B",
      },
      teal: {
        DEFAULT: "#30797D",
      },
      white: {
        DEFAULT: "#ffffff",
        alpha: "#ffffff80",
        translucent: "#ffffff33",
      },
      black: {
        DEFAULT: "#1C2126",
        alpha: "#1C212680",
      },
      offwhite: {
        DEFAULT: "#FAF2DC",
      },
      gray: {
        DEFAULT: "#EDF0F2",
      },
      bluegray: {
        DEFAULT: "#2E3842",
      },
      red: {
        DEFAULT: "#FF7777",
      },
      purple: {
        DEFAULT: "#F0B2FF",
      },
      "timeline-orange": {
        DEFAULT: "#FF8F59",
      },
      transparent: {
        DEFAULT: "transparent",
      },
    },
    extend: {
      fill: theme => theme("colors"),
      lineHeight: {
        looser: "1.65",
      },
      maxWidth: {
        "community-logo": "168px",
        card: "17em",
        "event-card": "360px",
        timeline: "280px",
      },
      backgroundSize: {
        pattern: "38px",
      },
      backgroundOpacity: {
        12: "0.12",
        55: "0.55",
      },
      zIndex: {
        60: 60,
      },
      width: {
        mandala: "508px",
      },
      height: {
        mandala: "508px",
      },
    },
  },
  variants: {
    boxShadow: ["hover", "group-hover", "responsive"],
  },
};
