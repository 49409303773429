import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Button, Icon } from "@atoms";
import { AppLink } from "@base";
import gsap from "gsap";

const MobileNav = ({ links }) => {
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;
  const mobileNav = useRef();
  useEffect(() => {
    if (mobileNav.current) {
      if (showNav) {
        gsap.to(mobileNav.current, { opacity: 1, pointerEvents: "auto" });
      } else {
        gsap.to(mobileNav.current, { opacity: 0, pointerEvents: "none" });
      }
    }
  }, [showNav]);
  return (
    <div
      ref={mobileNav}
      className={classNames(
        "fixed opacity-0 pointer-events-none pt-12 inset-0 bg-rose flex flex-col justify-start text-2xl opacity-0 md:hidden",
        {}
      )}
    >
      <div className="absolute top-0 p-6 text-white right-0 z-10">
        <button
          type="button"
          onClick={() => {
            dispatch({ type: "hideNav" });
          }}
        >
          <Icon
            name="close"
            className={classNames(
              "w-6 h-6 hover:text-yellow transition duration-500",
              {}
            )}
          />
        </button>
      </div>
      <ul className="flex flex-col pt-6 p-16 items-end justify-end text-right font-semibold tracking-0.5">
        {links?.map(link => {
          switch (link.style) {
            case "button":
              return (
                <li className="font-serif italic text-white mb-8">
                  <Button
                    onClick={() => {
                      dispatch({ type: "hideNav" });
                    }}
                    color="transparent"
                    size="lg"
                    to={link.link.url}
                  >
                    {link.link.text}
                  </Button>
                </li>
              );
            case "":
              return <li />;
            default:
              return (
                <li className="mb-8">
                  <AppLink
                    onClick={() => {
                      dispatch({ type: "hideNav" });
                    }}
                    className="font-serif italic text-white underline-1--hover-header transition duration-500"
                    to={link.link.url}
                  >
                    {link.link.text}
                  </AppLink>
                </li>
              );
          }
        })}
      </ul>
    </div>
  );
};

MobileNav.defaultProps = {};

MobileNav.propTypes = {};

export default MobileNav;
