import React from "react";
import propTypes from "prop-types";
import { Text, Button } from "@atoms";
import { HeadingWithCopy } from "@molecules";
import { useSupportData } from "@staticQueries";

const SupportHome = () => {
  const { title, content, button } = useSupportData();

  return (
    <>
      <HeadingWithCopy
        heading={title}
        headingColor="white"
        iconColor="yellow"
        className="text-4xl md:text-6xl"
      >
        <div className="flex flex-col w-full max-w-lg mx-auto">
          <Text className="text-white font-serif text-lg eading-snug mb-4 ">
            {content}
          </Text>

          <div className="flex flex-nowrap justify-center mt-4">
            <Button to={button?.url} size="md" color="yellow">
              <Text variant="body" className="font-serif italic">
                {button?.text}
              </Text>
            </Button>
          </div>
        </div>
      </HeadingWithCopy>
    </>
  );
};

SupportHome.defaultProps = {};

SupportHome.propTypes = {};

export default SupportHome;
