import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";

const CbSubheading = ({ subheading, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant="h4" className="text-red">
        {subheading}
      </Text>
    </CbWrapper>
  );
};

CbSubheading.defaultProps = {};

CbSubheading.propTypes = {};

export default CbSubheading;
