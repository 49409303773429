import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { TemplePattern, Mandala } from "@molecules";
import { Image, Text, Button, Container } from "@atoms";

const HeroImage = ({
  heading,
  content,
  image,
  color,
  templePattern,
  mandalaPattern,
  scrimOpacity,
}) => {
  const colors = {
    rose: "bg-rose",
    teal: "bg-teal",
  };
  return (
    <section
      className={`w-full flex justify-center items-center relative overflow-y-visible 
      ${colors[color]} 
      ${image ? `pt-24 pb-12 md:py-40` : `pt-24 pb-12 md:py-24`}`}
    >
      <Container>
        {templePattern && <TemplePattern />}
        {image && (
          <>
            <div
              className="w-full h-4/6 absolute left-0 bottom-0 opacity-25 bg-gradient-to-t from-black z-10"
              style={{ opacity: scrimOpacity }}
            />
            <div className="absolute inset-0 z-0">
              <Image {...image} fill />
            </div>
          </>
        )}
        <div className="relative z-20">
          <Text
            variant="h1"
            className="text-yellow mb-4 font-serif text-3xl md:text-5xl lg:text-7xl max-w-sm"
          >
            {heading}
          </Text>
          <Text
            variant="body"
            className="text-offwhite text-2xl w-full max-w-md"
          >
            {content}
          </Text>
        </div>
      </Container>
      {mandalaPattern && <Mandala position="hero" />}
    </section>
  );
};

HeroImage.defaultProps = {};

HeroImage.propTypes = {};

export default HeroImage;
