import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Icon } from "@atoms";
import { AppLink } from "@base";
import { MediaLink } from "@molecules";

const MediaPreview = ({
  name,
  description,
  type,
  time,
  image,
  button,
  mediaUrl,
  mediaEmbed,
  showDate,
}) => {
  const icon = {
    classes: "classes",
    podcasts: "podcasts",
    videos: "videos",
    articles: "articles",
    audioTeachings: "audioTeachings",
  };
  return (
    <div className="flex flex-col sm:flex-row text-white -m-1">
      <div className="w-36 flex-shrink-0">
        {image && <Image {...image} aspectratio="1:1" />}
        {!image && <Image icon="" />}
      </div>

      <div className="flex flex-col sm:px-4 py-4 sm:pt-0">
        <Text className="text-2xl text-white font-serif font-semibold">
          {name}
        </Text>
        <div className="grid grid-cols-3 flex-shrink self-stretch w-full">
          <div className="col-span-full flex flex-row mb-2 items-center row-span-full">
            <Text className="text-yellow font-serif font-semibold text-sm pr-1 leading-tight">
              <span className="flex items-baseline">
                <Icon
                  name={icon[type?.icon]}
                  fitHeight
                  className="w-3 h-3 mr-1 text-yellow self-center"
                />
                <span className="capitalize">{type?.text}</span>
              </span>
            </Text>
            {showDate && (
              <>
                <span className="border-r border-yellow block h-4 mx-1" />
                <Text className="text-yellow font-serif font-semibold text-sm pl-1 leading-0">
                  {time}
                </Text>
              </>
            )}
          </div>
          {!!mediaUrl?.length && (
            <div>
              <MediaLink url={mediaUrl} />
            </div>
          )}

          {!!mediaEmbed?.length && (
            <div>
              <div
                className="w-full my-12"
                dangerouslySetInnerHTML={{ __html: mediaEmbed }}
              />
            </div>
          )}

          <div className="col-span-3 flex flex-col space-y-2">
            <Text className="text-white font-serif font-semibold text-sm">
              {description}
            </Text>
            <div className="col-span-1 flex justify-start items-start">
              <AppLink to={button?.url}>
                <Text className="text-offwhite font-serif underline-1--hover font-semibold leading-tight italic tracking-wider max-w-max">
                  {button?.text}
                </Text>
              </AppLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MediaPreview.defaultProps = {};

MediaPreview.propTypes = {};

export default MediaPreview;
