import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query ErrorQuery {
    craft {
      globalSet(handle: "error") {
        ... on Craft_error_GlobalSet {
          heroHeading
          heroSubheading
          subheading
          fullWidthText
          linkList {
            ... on Craft_linkList_button_BlockType {
              uid
              type: typeHandle
              linkObj {
                text
                url
              }
            }
            ... on Craft_linkList_link_BlockType {
              uid
              type: typeHandle
              linkObj {
                text
                url
              }
            }
          }
          eventImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const {
    heroHeading,
    heroSubheading,
    subheading,
    eventImage,
    fullWidthText,
    linkList,
  } = craft.globalSet;
  return {
    hero: { heading: heroHeading, conten: heroSubheading },

    content: {
      heading: subheading,
      content: fullWidthText,
      image: resolveImage(eventImage),
      links: linkList.map(link => {
        return {
          ...link,
          link: link.linkObj,
        };
      }),
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
