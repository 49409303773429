import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Button, StickyElement, Container } from "@atoms";
import { TimelineCard, HeadingWithCopy, MediaPreview } from "@molecules";
import { AppLink } from "@base";
import { mandala } from "@images";

const ReopeningCovid = ({ heading, content, steps, visit, preview, links }) => {
  return (
    <div>
      <HeadingWithCopy
        heading={heading}
        headingColor="white"
        iconColor="yellow"
      >
        <Text className="text-offwhite font-serif w-full max-w-xl mx-auto leading-snug mb-8">
          {content}
        </Text>
      </HeadingWithCopy>

      {/* <Container className="relative flex flex-col md:flex-row justify-center md:space-x-8 mb-16 md:mb-32">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
          {steps?.map((step, i) => (
            <TimelineCard {...step} key={i} />
          ))}
        </div>
      </Container> */}

      <Container className="relative flex flex-col md:flex-row justify-between md:space-x-8">
        <StickyElement className="top-0 md:top-16">
          <div
            className="absolute top:8 md:-top-8 md:left-1/2 w-full px-2 py-24 md:py-40 overflow-visible bg-no-repeat bg-center bg-contain"
            style={{ backgroundImage: `url('${mandala}')` }}
          />
          <Text
            variant="h2--yellow"
            className="mb-4 font-serif text-4xl md:text-6xl lg:text-6xl max-w-max"
          >
            {visit?.heading}
          </Text>
          <Text
            variant="body"
            className="text-offwhite text-2xl w-full max-w-md font-semibold"
          >
            {visit?.content}
          </Text>

          <div className="mt-8 mb-16 space-y-4">
            <Button color="yellow" size="md" to={visit?.button?.url}>
              <Text variant="body" className="text-serif">
                {visit?.button?.text}
              </Text>
            </Button>
          </div>
        </StickyElement>

        <div className="max-w-md w-full">
          <div className="w-full relative">
            <div className="h-full w-full absolute top-0 left-0 bg-gradient-to-t z-10 from-black to-transparent z-20 inset-0" />
            <div className="relative z-0">
              <Image {...visit?.side?.image} />
            </div>
            <Text
              variant="h4"
              className="text-4xl font-bold text-offwhite font-serif absolute left-0 bottom-0 p-8 w-full block z-30"
            >
              {visit?.side?.heading}
            </Text>
          </div>

          <div className="px-4">
            <Text
              variant="body"
              className="text-offwhite font-serif font-bold text-lg my-8 pr-24"
            >
              {visit?.side?.text}
            </Text>
          </div>

          <div className="px-4">
            <Text variant="label" className="text-md mb-4 md:mt-2 text-yellow">
              Up Next:
            </Text>
            <div className="p-1">
              <MediaPreview {...preview} />
            </div>
          </div>

          <div className="px-4 mt-6">
            <Button size="xs" color="transparent" to={visit?.side?.button?.url}>
              <Text variant="body" className="font-serif italic">
                {visit?.side?.button?.text}
              </Text>
            </Button>
          </div>

          <div className="px-4 mt-8 space-y-2">
            <Text variant="h6" className="text-offwhite text-lg font-bold">
              {visit?.side?.donate?.title}
            </Text>

            <Text variant="body" className="text-offwhite max-w-xs w-full">
              {visit?.side?.donate?.content}
            </Text>

            <ul className="max-w-max w-full flex flex-col">
              {links?.map(link => {
                if (link.type === "button") {
                  return (
                    <li className="flex italic font-serif mt-1 mb-3">
                      <Button size="xs" color="transparent" to={link.link.url}>
                        {link.link.text}
                      </Button>
                    </li>
                  );
                }
                return (
                  <li className="flex mb-3">
                    <AppLink to={link.link.url}>
                      <Text
                        variant="body"
                        className="text-offwhite leading-tight font-semibold font-serif underline-1--hover italic tracking-wider"
                      >
                        {link.link.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

ReopeningCovid.defaultProps = {};

ReopeningCovid.propTypes = {};

export default ReopeningCovid;
