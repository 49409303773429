import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon } from "@atoms";
import { mandala } from "@images";

const FullWidth = ({ heading }) => {
  return (
    <div
      className="flex flex-col justify-center w-full md:w-10/12 m-auto mt-6 md:mt-0 mb-8 px-2 py-28 md:py-24 overflow-visible bg-no-repeat bg-center bg-contain"
      style={{ backgroundImage: `url('${mandala}')` }}
    >
      <div className="flex justify-center">
        <Icon name="ramFlowers" className="h-7 w-32 text-yellow" />
      </div>

      <Text
        variant="h3"
        className="font-serif text-offwhite text-center py-8 leading-tight font-bold"
      >
        {heading}
      </Text>

      <div className="flex justify-center">
        <Icon name="ramSingle" className="h-10 w-10 text-yellow" />
      </div>
    </div>
  );
};

FullWidth.defaultProps = {};

FullWidth.propTypes = {};

export default FullWidth;
