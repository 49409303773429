const resolveImage = image => {
  if (image && (image[0] || typeof image === "object")) {
    const { status, title, url, height, width, hasFocalPoint, focalPoint } =
      image[0] || image;
    return {
      status,
      title,
      url,
      height,
      width,
      focalPoint: hasFocalPoint ? focalPoint : false,
    };
  }
  return null;
};

export default resolveImage;
