import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import Text from "./Text";

const Field = ({
  placeholder,
  type,
  errorMessage,
  className: _className,
  error,
  onChange,
}) => {
  const [value, setValue] = useState();
  return (
    <div className="flex flex-col w-full">
      <input
        type={type}
        className={`bold border-b border-yellow bg-transparent text-yellow p-2 pl-0 pt-4 font-serif placeholder-yellow italic ${_className}}`}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && (
        <Text className="text-yellow text-sm font-serif italic">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

Field.defaultProps = {
  type: "text",
};

Field.propTypes = {};

export default Field;
