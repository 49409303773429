import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const LeftRightHome = ({ content, link, image, position, last }) => {
  let positionClass;
  let marginClasses;
  switch (position) {
    case "left":
      positionClass = "flex-col md:flex-row";
      // marginClasses = "mt-8 ml-0 md:mt-0 md:ml-24";
      break;
    case "right":
      positionClass = "flex-col md:flex-row-reverse";
      // marginClasses = "mt-8 mr-0 md:mt-0 md:mr-24";
      break;
    default:
      positionClass = "";
      // marginClasses = "";
      break;
  }
  return (
    <div
      className={`flex ${positionClass} mx-auto my-6 -mx-4 sm:-mx-6 md:-mx-8`}
    >
      <div
        className={classNames(
          "w-full h-64 md:h-auto my-8 md:w-1/2 relative flex flex-grow md:-my-8 px-4 sm:px-6 md:px-8",
          { "md:mb-0": last }
        )}
      >
        <div className="flex-grow relative">
          <div className="absolute inset-0 shadow-md">
            <Image {...image} fill />
          </div>
        </div>
      </div>

      <div
        className={classNames(
          `${marginClasses} w-full md:w-1/2 pt-0 pb-0 px-6 md:px-8`,
          {
            "md:pb-0 md:pt-24": last,
            "md:pb-32 md:pt-8": !last,
          }
        )}
      >
        <Text className="text-offwhite font-serif font-bold text-xl tracking-wider mb-8 align-top left-right-home leading-normal">
          {content}
        </Text>

        <AppLink to={link?.url}>
          <Text className="text-offwhite font-serif underline-1--hover font-semibold leading-tight italic tracking-wider max-w-max">
            {link?.text}
          </Text>
        </AppLink>
      </div>
    </div>
  );
};

LeftRightHome.defaultProps = {};

LeftRightHome.propTypes = {};

export default LeftRightHome;
