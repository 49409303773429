import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Button, Icon } from "@atoms";
import { AppLink } from "@base";
import { titleCase } from "@utils";

const MediaCard = ({
  name,
  description,
  type,
  time,
  image,
  button,
  repeats,
  link,
  url,
  dark,
  showDate,
}) => {
  const icon = {
    specialEvents: "specialEvents",
    recurringRituals: "om",
    talksPresentations: "star",
    performanceArts: "pray",
    classes: "classes",
    podcasts: "podcasts",
    videos: "videos",
    articles: "articles",
    audioTeachings: "audioTeachings",
  };
  return (
    <AppLink
      to={button?.url}
      className={classNames(
        "flex text-left flex-col items-stretch overflow-hidden mb-6 sm:mb-0 shadow-md sm:shadow-none sm:flex-row transition duration-500 group -mt-px",
        {
          "bg-black-alpha hover:bg-white text-white hover:text-blue": dark,
          "bg-white text-blue": !dark,
        }
      )}
    >
      <div className="w-full sm:w-36 flex-shrink-0">
        {image && (
          <>
            <div
              className="hidden sm:block"
              style={{ height: "9rem", minHeight: "100%" }}
            >
              <Image {...image} fill />
            </div>
            <div className="sm:hidden -m-px">
              <Image {...image} aspectratio="4:3" />
            </div>
          </>
        )}
        {!image && <Image icon="" />}
      </div>

      <div className="flex flex-row p-4 w-full">
        <div className="grid grid-cols-4 flex-shrink self-stretch w-full">
          <div className="col-span-full flex flex-row mb-2 items-center row-span-full">
            <Text className="font-serif font-semibold text-sm pr-1 leading-tight">
              <span className="flex flex-row items-baseline">
                <Icon
                  name={icon[type?.icon]}
                  fitHeight
                  className={classNames("w-3 h-3 mr-1 self-center", {
                    "text-yellow transition duration-500 group-hover:text-orange": dark,
                    "text-orange": !dark,
                  })}
                />
                <span
                  className={classNames("capitalize", {
                    "text-yellow transition duration-500 group-hover:text-orange": dark,
                    "text-orange": !dark,
                  })}
                >
                  {titleCase(type?.text)}
                </span>
              </span>
            </Text>
            {showDate && (
              <>
                <span
                  className={`border-r block h-3 
              ${
                button && !link
                  ? "border-orange"
                  : "border-yellow transition duration-500 group-hover:border-orange"
              }`}
                />
                <Text
                  className={classNames(
                    "font-serif font-semibold text-sm pl-1 leading-0",
                    {
                      "text-yellow transition duration-500 group-hover:text-orange": dark,
                      "text-orange": !dark,
                    }
                  )}
                >
                  {time}
                </Text>
              </>
            )}
          </div>

          <div
            className={`
            ${button ? "col-span-3" : "col-span-4"} 
            flex flex-col space-y-2 mr-4`}
          >
            <Text
              className={classNames("font-serif font-semibold ", {
                "text-offwhite transition duration-500 group-hover:text-teal": dark,
                "text-teal": !dark,
              })}
            >
              {name}
            </Text>

            <Text
              className={classNames("font-serif font-semibold text-sm ", {
                "text-yellow transition duration-500 group-hover:text-rose": dark,
                "text-rose": !dark,
              })}
            >
              {description}
            </Text>
          </div>
          {button?.text && !dark && (
            <div className="col-span-1 flex justify-end self-center">
              <Button size="md" color="yellow">
                <Text className="font-serif italic">{button?.text}</Text>
              </Button>
            </div>
          )}
        </div>
      </div>
    </AppLink>
  );
};

MediaCard.defaultProps = {};

MediaCard.propTypes = {};

export default MediaCard;
