import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { useAnnouncementData } from "@staticQueries";
import { Text, Container, Icon } from "@atoms";
import { useWindowSize } from "@designbycosmic/cosmic-react-resize-hook";
import gsap from "gsap";
import { AppLink } from "@base";

const Announcement = () => {
  const announcement = useRef();
  const { heading, link } = useAnnouncementData();
  const [visible, setVisible] = useState(heading?.length && link?.url);
  const [closed, setClosed] = useState(!(heading?.length && link?.url));

  const { innerWidth: windowSize } = useWindowSize();

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!closed) {
        if (visible) {
          setTimeout(() => {
            gsap.to("#app_layout", {
              // y: announcement.current.clientHeight,
              marginTop: announcement.current.clientHeight,
              display: "flex",
            });
          }, 500);
        } else {
          gsap.to("#app_layout", {
            marginTop: 0,
            onComplete: () => {
              gsap.set(announcement.current, { display: "none" });
            },
          });
          setClosed(true);
        }
      }
    }
  }, [visible, windowSize]);

  return (
    <div
      ref={announcement}
      className="fixed p-3 bg-yellow top-0 left-0 right-0 flex flex-col sm:flex-row z-0 text-rose"
    >
      <Container>
        <div className="flex flex-col sm:flex-row justify-between">
          <Text variant="body">{heading}</Text>
          <div className="flex items-center mt-3 sm:mt-0 sm:ml-3 justify-between">
            <AppLink to={link?.url} className="flex-shrink-0">
              <Text className="font-serif underline-1-rose font-semibold leading-tight italic tracking-wider -mt-1">
                {link?.text}
              </Text>
            </AppLink>
            <button
              type="button"
              className="ml-3"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icon name="close" className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

Announcement.defaultProps = {};

Announcement.propTypes = {};

export default Announcement;
