import React from "react";
import propTypes from "prop-types";
import { Text, CbWrapper } from "@atoms";

const CbBlockQuote = ({ quote, citation, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="md:-ml-6 lg:-ml-12 lg:pt-4 lg:pb-2 max-w-lg">
        <Text variant="blockquote" className="mb-3 font-bold font-serif">
          {quote}
        </Text>
        {citation && (
          <Text variant="body--large-tight" className="citation mt-4">
            {citation}
          </Text>
        )}
      </div>
    </CbWrapper>
  );
};

CbBlockQuote.defaultProps = {};

CbBlockQuote.propTypes = {};

export default CbBlockQuote;
