import React, { useState, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Container, StickyElement } from "@atoms";
import { AppLink } from "@base";

const RelatedActivitiesAbout = ({ activities }) => {
  const [activeRelated, setActiveRelated] = useState(0);
  const stickyRef = useRef();
  const timeout = useRef();
  return (
    <Container variant="sm">
      <div className={classNames("lg:hidden -mt-16", {})}>
        <div className="mb-16">
          {activities?.map((activity, i) => {
            return (
              <div className="my-12">
                <Text
                  variant="h5"
                  className={`text-yellow hover:text-offwhite mb-6 cursor-pointer font-bold 
                ${activeRelated === i ? "text-offwhite" : ""} `}
                >
                  {activity?.name}
                </Text>
                <div className="flex flex-col space-y-6">
                  <div className="shadow-lg self-start w-full">
                    <Image {...activity?.image} aspectratio="8:5" />
                  </div>

                  <Text variant="body" className="text-offwhite max-w-prose">
                    {activity.description}
                  </Text>

                  <div>
                    <AppLink to={activity?.link?.url}>
                      <Text className="text-offwhite font-serif leading-tight font-semibold underline-1--hover italic tracking-wider max-w-max">
                        {activity?.link?.text}
                      </Text>
                    </AppLink>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="hidden lg:flex justify-between w-full">
        <div className="mb-16 md:pt-10">
          {activities?.map((activity, i) => {
            return (
              <Text
                variant="h5"
                className={`text-yellow hover:text-offwhite cursor-pointer font-bold 
                ${activeRelated === i ? "text-offwhite" : ""} `}
              >
                <button
                  type="button"
                  onClick={() => {
                    setActiveRelated(i);
                    setTimeout(() => {
                      stickyRef.current.scrollIntoView({ behavior: "smooth" });
                    });
                  }}
                  onMouseEnter={() => {
                    if (timeout.current) {
                      clearTimeout(timeout.current);
                    }
                    timeout.current = setTimeout(() => {
                      setActiveRelated(i);
                    }, 250);
                  }}
                  onMouseLeave={() => {
                    if (timeout.current) {
                      clearTimeout(timeout.current);
                    }
                  }}
                  className="text-left "
                >
                  {activity?.name}
                </button>
              </Text>
            );
          })}
        </div>

        {activities && (
          <StickyElement className="top-0">
            <div ref={stickyRef} className="w-full md:pt-10 max-w-md">
              <div className="flex flex-col space-y-6">
                <div className="shadow-lg self-start w-full">
                  <Image
                    {...activities[activeRelated]?.image}
                    aspectratio="8:5"
                  />
                </div>

                <Text variant="body" className="text-offwhite max-w-prose">
                  {activities[activeRelated]?.description}
                </Text>

                <div>
                  <AppLink to={activities[activeRelated]?.link?.url}>
                    <Text className="text-offwhite font-serif leading-tight font-semibold underline-1--hover italic tracking-wider max-w-max">
                      {activities[activeRelated]?.link?.text}
                    </Text>
                  </AppLink>
                </div>
              </div>
            </div>
          </StickyElement>
        )}
      </div>
    </Container>
  );
};

RelatedActivitiesAbout.defaultProps = {};

RelatedActivitiesAbout.propTypes = {};

export default RelatedActivitiesAbout;
