import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, Button, StickyElement, Container } from "@atoms";
import { TimelineCard, HeadingWithCopy } from "@molecules";
import { AppLink } from "@base";
import { mandala } from "@images";

const SriBabaAbout = ({ heading, text, button, content }) => {
  return (
    <div>
      <Container className="relative flex flex-col mt-28 md:flex-row justify-between md:space-x-8">
        <StickyElement className="top-0 md:top-16">
          <div
            className="absolute top:8 md:-top-8 md:left-2/3 w-full px-2 py-24 md:py-40 overflow-visible bg-no-repeat bg-center bg-contain"
            style={{ backgroundImage: `url('${mandala}')` }}
          />
          <Text
            variant="h2"
            className="text-teal mb-4 font-serif text-4xl md:text-6xl lg:text-6xl max-w-max"
          >
            {heading}
          </Text>
          <Text
            variant="body"
            className="text-blue text-2xl w-full max-w-md font-semibold"
          >
            {text}
          </Text>

          <div className="mt-8 mb-16 space-y-4">
            <Button color="yellow" size="md" to={button?.url}>
              <Text variant="body" className="text-serif">
                {button?.text}
              </Text>
            </Button>
          </div>
        </StickyElement>

        <div className="md:w-1/2 w-full">
          <div className="w-full relative">
            <div
              className="h-full w-full absolute top-0 left-0 bg-gradient-to-t z-10 from-black to-transparent z-20 inset-0"
              style={{ opacity: content?.scrimOpacity }}
            />
            <div className="relative z-0">
              <Image {...content?.image} />
            </div>
            <Text
              variant="h4"
              className="text-4xl font-bold text-offwhite font-serif absolute left-0 bottom-0 p-8 w-full block z-30"
            >
              {content?.heading}
            </Text>
          </div>

          <div className="px-4">
            <Text
              variant="body"
              className="text-blue font-serif font-bold text-lg my-8 pr-24"
            >
              {content?.text}
            </Text>

            <Image {...content.innerImage} />
          </div>

          <div className="px-4 mt-8 space-y-2">
            <Text variant="h6" className="text-teal text-lg font-bold">
              {content?.subhead}
            </Text>
            <Text variant="body" className="text-blue text-md font-bold">
              {content?.innerText}
            </Text>
          </div>
        </div>
      </Container>
    </div>
  );
};

SriBabaAbout.defaultProps = {};

SriBabaAbout.propTypes = {};

export default SriBabaAbout;
