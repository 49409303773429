import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import Player from "@vimeo/player";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import gsap from "gsap";

const BackgroundVideo = React.memo(
  ({ url, preserveAspectRatio, showControls, autoplay, play, forcePlay }) => {
    const player = useRef();
    const video = useRef();
    const split = url.split("/");
    const id = split[split.length - 1];
    const handleEnter = () => {
      if (player.current) {
        player.current.play();
      }
    };
    useEffect(() => {
      if (video.current) {
        // TODO: Clean this up
        // eslint-disable-next-line no-constant-condition
        if (true) {
          player.current = new Player(video.current, {
            id,
            muted: true,
            autopause: false,
            background: true,
          });
        } else {
          player.current = new Player(video.current, {
            id,
            autoplay: autoplay || forcePlay,
            controls: showControls,
            autopause: false,
            loop: true,
            muted: autoplay,
          });
        }
        if (preserveAspectRatio) {
          player.current.pause();
        }
        // eslint-disable-next-line func-names
        player.current.on("play", function () {
          // eslint-disable-next-line react/no-this-in-sfc
          gsap.to(this.element, {
            opacity: 1,
          });
        });
      }
    }, []);

    return (
      <InView
        onEnter={autoplay ? handleEnter : null}
        observerOptions={{
          threshold: 0.01,
        }}
        unobserveAfterEntry
        // ref={observer}
        // style={{ ...style, ...initialStyle }}
        className={`${
          !preserveAspectRatio ? "absolute inset-0 overflow-hidden" : ""
        } w-full`}
      >
        <div>
          <div
            ref={video}
            className={`video-player${
              !preserveAspectRatio ? "--fill absolute inset-0" : ""
            }`}
          />
        </div>
      </InView>
    );
  }
);

BackgroundVideo.defaultProps = {
  loop: true,
  autoplay: true,
  showControls: false,
};

BackgroundVideo.propTypes = {};

export default BackgroundVideo;
