import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, Container, StickyElement } from "@atoms";
import { AppLink } from "@base";
import { mandala } from "@images";

const ReservationsVisit = ({ heading, content, times, visits }) => {
  return (
    <Container className="relative flex flex-col md:flex-row justify-between md:space-x-8">
      <StickyElement className="top-0 md:top-16">
        <div
          className="absolute top:8 md:-top-8 md:left-2/3 w-full px-2 py-24 md:py-40 overflow-visible bg-no-repeat bg-center bg-contain"
          style={{ backgroundImage: `url('${mandala}')` }}
        />
        <Text
          variant="h2"
          className="text-teal mb-4 font-serif text-4xl md:text-6xl lg:text-6xl max-w-min"
        >
          {heading}
        </Text>
        <Text
          variant="body"
          className="text-teal text-2xl w-full max-w-sm font-bold"
        >
          {content}
        </Text>

        <div className="my-16 space-y-4">
          <div>
            <Text variant="h7" className="font-serif font-bold text-teal">
              {times.wdHeading}
            </Text>
            <Text variant="body--tight" className="text-rose mt-2">
              {times.wdCopy}
            </Text>
          </div>

          <div>
            <Text variant="h7" className="font-serif font-bold text-teal">
              {times.weHeading}
            </Text>
            <Text variant="body--tight" className="text-rose mt-2">
              {times.weCopy}
            </Text>
          </div>
        </div>
      </StickyElement>

      <div className="max-w-md w-full">
        <div className="w-full relative">
          <div
            className="h-full w-full absolute top-0 left-0 bg-gradient-to-t z-10 from-black to-transparent z-20 inset-0"
            style={{ opacity: visits?.content?.scrimOpacity }}
          />
          <div className="relative z-0">
            <Image {...visits?.content?.image} />
          </div>
          <Text
            variant="h4"
            className="text-4xl font-bold text-offwhite font-serif absolute left-0 bottom-0 p-8 w-full block z-30"
          >
            {visits?.headline}
          </Text>
        </div>

        <div className="px-4">
          <Text
            variant="body"
            className="text-teal font-serif font-bold text-lg my-8 pr-24"
          >
            {visits?.subhead}
          </Text>

          <div className="space-x-4">
            {visits?.content?.buttons.map((button, i) => {
              return (
                <Button size="md" color="yellow" to={button?.url}>
                  <Text variant="body" className="font-serif italic">
                    {button?.text}
                  </Text>
                </Button>
              );
            })}
          </div>

          <div className="mt-8">
            <AppLink to={visits?.content?.youtube?.url}>
              <Text
                variant="body"
                className="text-rose italic underline-1-rose w-full max-w-max"
              >
                {visits?.content?.youtube?.text}
              </Text>
            </AppLink>
          </div>

          <div>
            <Text
              variant="h6"
              className="text-teal font-serif font-bold text-xl md:text-3xl mt-8 mb-4"
            >
              {visits?.content?.heading}
            </Text>
            <Text
              variant="body"
              className="text-teal font-serif font-semibold mt-8 mb-4"
            >
              {visits?.content?.text}
            </Text>
          </div>

          <div className="mt-8">
            <AppLink to={visits?.content?.donate?.url}>
              <Text
                variant="body"
                className="text-rose italic underline-1-rose w-full max-w-max"
              >
                {visits?.content?.donate?.text}
              </Text>
            </AppLink>
          </div>
        </div>
      </div>
    </Container>
  );
};

ReservationsVisit.defaultProps = {};

ReservationsVisit.propTypes = {};

export default ReservationsVisit;
